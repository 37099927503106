import React, { useState } from 'react';
import './MapComponent.css';
import { uniqueFunctions, funcColours, buildingTypeColours, sanierungsstandColours } from './LegendDefs';


const eightStepMagma = ['#fcfdbf', '#febb81', '#f8765c', '#d3436e', '#982d80', '#5f187f', '#221150', '#000004'];
const eightStepViridis = ['#fde725', '#a0da39', '#4ac16d', '#1fa187', '#277f8e', '#365c8d', '#46327e', '#440154'];

const functionColorMap = new Map();
uniqueFunctions.forEach((func) => {
    if (!func || !func.funcType) return;
    if (!functionColorMap.has(func.funcType)) {
        functionColorMap.set(func.funcType, funcColours.find((o) => {
            return o.funcType === func.funcType
        }).colour);
    }
});

export const LegendComponent = ({ selectedLegend }) => {
    const [isLegendVisible, setIsLegendVisible] = useState(true);

    const toggleLegend = () => {
        setIsLegendVisible(!isLegendVisible);
    };

    const renderLegend = () => {
        if (!isLegendVisible) return null;
        switch (selectedLegend.value) {
            case 'nutzung':
                return functionLegend;
            case 'gebaeudetyp':
                return buildingTypeLegend;
            case 'sanierungsstand':
                return sanierungstandLegend;
            case 'nutzflaeche':
                return heatAreaLegend;
            case 'raum_waerme_wasser_waerme_bedarf':
                return wbAbsLegend;
            case 'spez_raum_waerme_wasser_waerme_bedarf':
                return wbSPZLegend;
            default:
                return null;
        }
    };
    const renderWLLegend = () => {
        if (!isLegendVisible) return null;
        return wlLegend;
    };

    return (
        <div className="legend-container">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {renderWLLegend()}
                {renderLegend()}

            </div>

            <div className="">
                <button className="legend-button" onClick={toggleLegend}>
                    {isLegendVisible ? "Hide Legend" : "Show Legend"}
                </button>
            </div>
        </div>
    );
}

const functionLegendItems = Array.from(functionColorMap.entries()).map(([shortenedText, color]) => (
    <div className="legend-item" key={shortenedText}>
        <div className="legend-color" style={{ backgroundColor: color }}></div>
        <div className="legend-label">{shortenedText}</div>
    </div>
));



const functionLegend = (
    <div className="legend">
        <div className="legend-title">Building Usage</div>
        {functionLegendItems}
    </div>
);

const buildingTypeLegendItems = buildingTypeColours.map(({ buildingType, colour }) => (
    <div className="legend-item" key={buildingType}>
        <div className="legend-color" style={{ backgroundColor: colour }}></div>
        <div className="legend-label">{buildingType}</div>
    </div>
));

const buildingTypeLegend = (
    <div className="legend">
        <div className="legend-title">Building Type</div>
        {buildingTypeLegendItems}
    </div>
);

const sanierungstandLegendItems = sanierungsstandColours.map(({ buildingType, colour }) => (
    <div className="legend-item" key={buildingType}>
        <div className="legend-color" style={{ backgroundColor: colour }}></div>
        <div className="legend-label">{buildingType}</div>
    </div>
));

const sanierungstandLegend = (
    <div className="legend">
        <div className="legend-title">Energy Efficiency Class</div>
        {sanierungstandLegendItems}
    </div>
);


export const heatAreaLegend = (
    <div className="legend">
        <div className="legend-title"> Heat area (m²)</div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(204, 229, 255)' }}></div>
            <div className="legend-label"> &lt; 20</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(153, 204, 255)' }}></div>
            <div className="legend-label">20.1-100.0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(102, 178, 255)' }}></div>
            <div className="legend-label">100.1-500.0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(51, 153, 255)' }}></div>
            <div className="legend-label">500.1-1000.0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(0, 128, 255)' }}></div>
            <div className="legend-label">1000.1-2000.0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(0, 102, 204)' }}></div>
            <div className="legend-label">2000.1-3000.0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(0, 77, 153)' }}></div>
            <div className="legend-label">3000.1-4000.0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(0, 51, 102)' }}></div>
            <div className="legend-label">4000.1-5000.0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: 'rgb(0, 26, 51)' }}></div>
            <div className="legend-label">&gt; 5000.1  </div>
        </div>
    </div>
);

export const wbSPZLegend = (
    <div className="legend">
        <div className="legend-title">Specific Heat demand <br /> (kWh/m²a)</div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[0] }}></div>
            <div className="legend-label">0,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[1] }}></div>
            <div className="legend-label">0,1-50,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[2] }}></div>
            <div className="legend-label">50,1-100,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[3] }}></div>
            <div className="legend-label">100,1-150,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[4] }}></div>
            <div className="legend-label">150,1-200,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[5] }}></div>
            <div className="legend-label">200,1-250,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[6] }}></div>
            <div className="legend-label">250,1-300,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[7] }}></div>
            <div className="legend-label">300,0 &gt;=</div>
        </div>
    </div>
);
export const wlLegend = (
    <div className="legend">
        <div className="legend-title">Heat line density <br /> (MWh/ma)</div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[0] }}></div>
            <div className="legend-label">No Value or &lt; 1,5</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[1] }}></div>
            <div className="legend-label">1,5-3,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[2] }}></div>
            <div className="legend-label">3,1-4,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[3] }}></div>
            <div className="legend-label">4,1-5,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[4] }}></div>
            <div className="legend-label">5,1-6,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[5] }}></div>
            <div className="legend-label">6,1-8,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[6] }}></div>
            <div className="legend-label">8,1-10,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepViridis[7] }}></div>
            <div className="legend-label">10,0 &gt;=</div>
        </div>

    </div>
);

export const wbAbsLegend = (
    <div className="legend">
        <div className="legend-title">Abs. Heat demand <br /> (MWh/a)</div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[0] }}></div>
            <div className="legend-label">&lt; 50,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[1] }}></div>
            <div className="legend-label">50,0 - 100,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[2] }}></div>
            <div className="legend-label">100,1 - 250,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[3] }}></div>
            <div className="legend-label">250,1 - 500,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[4] }}></div>
            <div className="legend-label">500,1 - 750,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[5] }}></div>
            <div className="legend-label">750,1 - 1 000,0</div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[6] }}></div>
            <div className="legend-label">1 000,1 - 10 000,0 </div>
        </div>
        <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: eightStepMagma[7] }}></div>
            <div className="legend-label">10 000,0 &gt;= </div>
        </div>
    </div>
);


export function legendHandler(selectedLegend, feature, zoom, lastSelected) {
    let style = {};
    let option = selectedLegend.value;
    switch (option) {
        case 'nutzung':
            const funktion = feature.properties.nutzung;
            style = selectLegendFunction(funktion);
            break;
        case 'gebaeudetyp':
            const buildingType = feature.properties.gebaeudetyp;
            style = selectLegendBuildingType(buildingType);
            break;
        case 'sanierungsstand':
            const sanierungsstand = feature.properties.sanierungsstand;
            style = selectLegendSanierungsstand(sanierungsstand);
            break;
        case 'nutzflaeche':
            const nutzflaeche = feature.properties.nutzflaeche;
            style = selectLegendNutzflaeche(nutzflaeche);
            break;
        case 'spez_raum_waerme_wasser_waerme_bedarf':
            const WBSpzValue = feature.properties.spez_raum_waerme_wasser_waerme_bedarf;
            style = selectLegendWBSpz(WBSpzValue);
            break;
        case 'raum_waerme_wasser_waerme_bedarf':
            const RW_WW = feature.properties.raum_waerme_wasser_waerme_bedarf;
            style = selectLegendRW_WW(RW_WW);
    }

    const weight = zoom < 17 ? 0 : 0.5;
    const isSelected = feature.properties.fest_id === lastSelected;
    style = { ...style, weight: isSelected ? 5.0 : weight, fillOpacity: 1.0, color: isSelected ? 'blue' : 'black' };

    return style;

}

export function legendHandlerWL(feature, zoom, lastSelected) {
    let style = {};
    const weight = zoom < 17 ? 2 : 3;
    const isSelected = feature.properties.WLD_ID === lastSelected;
    style = { weight: weight, fillOpacity: 1.0, color: isSelected ? 'blue' : selectLegendWL(feature.properties.Waermedichte_RW_WW_GHD_PW).color };

    return style;

}
function selectLegendFunction(funktion) {

    const tobe = funktion.replace(/\s/g, '');

    const funcType = uniqueFunctions.find((o) => {
        const is = o.fullText.replace(/\s/g, '');
        return is === tobe
    }).funcType;

    const colour = funcColours.find((o) => {
        return o.funcType === funcType
    })?.colour || '#d3d3d3';

    return { fillColor: colour };
}


function selectLegendBuildingType(buildingType) {
    const string = buildingType.replace(/\s/g, '');

    const [type, age] = string.split('_');
    const colour = buildingTypeColours.find((o) => {
        return o.buildingType == type;
    })?.colour || '#d3d3d3';

    return { fillColor: colour };
}
function selectLegendSanierungsstand(feat) {
    const roundedFeat = Math.round(feat);

    switch (roundedFeat) {
        case 0:
            return { fillColor: 'white' };
        case 1:
            return { fillColor: '#b0ff00' };
        case 2:
            return { fillColor: '#bed600' };
        case 3:
            return { fillColor: '#c1ae00' };
        case 4:
            return { fillColor: '#ba8800' };
        case 5:
            return { fillColor: '#ab6400' };
        case 6:
            return { fillColor: '#954200' };
        case 7:
            return { fillColor: '#7b2200' };
        default:
            return { fillColor: '#5c0000' };
    }
}

function selectLegendNutzflaeche(feat) {

    switch (true) {
        case (feat < 20):
            return { fillColor: 'rgb(204, 229, 255)' };
        case (feat < 100):
            return { fillColor: 'rgb(153, 204, 255)' };
        case (feat < 500):
            return { fillColor: 'rgb(102, 178, 255)' };
        case (feat < 1000):
            return { fillColor: 'rgb(51, 153, 255)' };
        case (feat < 2000):
            return { fillColor: 'rgb(0, 128, 255)' };
        case (feat < 3000):
            return { fillColor: 'rgb(0, 102, 204)' };
        case (feat < 4000):
            return { fillColor: 'rgb(0, 77, 153)' };
        case (feat < 5000):
            return { fillColor: 'rgb(0, 51, 102)' };
        default:
            return { fillColor: 'rgb(0, 26, 51)' };
    }
}

function selectLegendWBSpz(feat) {

    switch (true) {
        case (feat < 50.0):
            return { fillColor: eightStepMagma[1] };
        case (feat < 100.0):
            return { fillColor: eightStepMagma[2] };
        case (feat < 150.0):
            return { fillColor: eightStepMagma[3] };
        case (feat < 200.0):
            return { fillColor: eightStepMagma[4] };
        case (feat < 250.0):
            return { fillColor: eightStepMagma[5] };
        case (feat < 300.0):
            return { fillColor: eightStepMagma[6] };
        case (feat >= 300.0):
            return { fillColor: eightStepMagma[7] };
        default:
            return { fillColor: eightStepMagma[0] };
    }
}

function selectLegendRW_WW(feat) {

    switch (true) {
        case (feat < 50000):
            return { fillColor: eightStepMagma[0] };
        case (feat < 100000):
            return { fillColor: eightStepMagma[1] };
        case (feat < 250000):
            return { fillColor: eightStepMagma[2] };
        case (feat < 500000):
            return { fillColor: eightStepMagma[3] };
        case (feat < 750000):
            return { fillColor: eightStepMagma[4] };
        case (feat < 1000000):
            return { fillColor: eightStepMagma[5] };
        case (feat < 10000000):
            return { fillColor: eightStepMagma[6] };
        case (feat >= 10000000):
            return { fillColor: eightStepMagma[7] };
        default:
            return { fillColor: eightStepMagma[0] };
    }
}

function selectLegendWL(feat) {

    switch (true) {
        case (feat < 1500):
            return { color: eightStepViridis[0] };
        case (feat < 3000):
            return { color: eightStepViridis[1] };
        case (feat < 4000):
            return { color: eightStepViridis[2] };
        case (feat < 5000):
            return { color: eightStepViridis[3] };
        case (feat < 6000):
            return { color: eightStepViridis[4] };
        case (feat < 8000):
            return { color: eightStepViridis[5] };
        case (feat < 10000):
            return { color: eightStepViridis[6] };
        case (feat >= 10000):
            return { color: eightStepViridis[7] };
        default:
            return { color: eightStepViridis[0] };
    }
}

export default LegendComponent;
