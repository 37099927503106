// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100vh;
  width: 100vw;
  overflow: visible;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid gray;

}

.sidebar {
  position: absolute;
  top: 140px;
  left: 0px;
  height: calc(100vh - 140px);
  background-color: white;
  width: 100vw; 
  max-width: 600px; 
  z-index: 1000;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  border-right: 1px solid gray;
  overflow-y: auto;
  overflow-x: auto; 
}

.section {
  border: 1px solid gray;
  padding: 10px;
}

.flexCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mapContainer {
  flex: 1 1;
  overflow: visible;
  position: relative;
}

.absoluteFill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/ui/Layout.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,sBAAsB;;AAExB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,0BAA0B;EAC1B,6BAA6B;EAC7B,4BAA4B;EAC5B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;AACT;;;AAGA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".container {\n  height: 100vh;\n  width: 100vw;\n  overflow: visible;\n  margin: 0;\n  padding: 0;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px;\n  border: 1px solid gray;\n\n}\n\n.sidebar {\n  position: absolute;\n  top: 140px;\n  left: 0px;\n  height: calc(100vh - 140px);\n  background-color: white;\n  width: 100vw; \n  max-width: 600px; \n  z-index: 1000;\n  border-top: 1px solid gray;\n  border-bottom: 1px solid gray;\n  border-right: 1px solid gray;\n  overflow-y: auto;\n  overflow-x: auto; \n}\n\n.section {\n  border: 1px solid gray;\n  padding: 10px;\n}\n\n.flexCenter {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.mapContainer {\n  flex: 1;\n  overflow: visible;\n  position: relative;\n}\n\n.absoluteFill {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
