import React from 'react';

const convertEEK = (value) => {
    const eekMap = {
        1: 'A+',
        2: 'A',
        3: 'B',
        4: 'C',
        5: 'D',
        6: 'E',
        7: 'F',
        8: 'G',
        9: 'H',
    };

    return eekMap[value] || 'Unknown';
};

function areaDisplay(number) {
    return number?.toFixed(0).toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function absWBDisplay(number) {
    return (number / 1e3).toFixed(1).toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function spWBDisplay(number) {
    return number.toFixed(1).toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const SingleBuildingInfo = ({ featureAttributes, downloadJSON }) => {
    const feature_props = featureAttributes?.properties || null;

    // Check if feature_props contains WLD_ID; if so, it's a heat line feature (WL)
    const featureAttributesWL = feature_props?.WLD_ID ? feature_props : null;

    if (featureAttributesWL) {
        return (
            <div style={{ borderBottom: '1px solid gray', padding: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>Single heat line</h2>
                    {featureAttributesWL && (
                        <button
                            className="button"
                            onClick={() => downloadJSON(featureAttributes, 'heat_line_data.json')}
                        >
                            Download
                        </button>
                    )}
                </div>
                {featureAttributesWL ? (
                    <>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Anzahl Adressen:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{featureAttributesWL.Anzahl_Adressen || '-'}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Anzahl Objekte:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{featureAttributesWL.Anzahl_Objekte || '-'}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Strassenlänge:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{areaDisplay(featureAttributesWL.Strassenlaenge)}</td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> m</td>
                                </tr>
                                {/* <tr>
                                    <td style={{ width: '200px' }}><b>Residential heat demand:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(featureAttributesWL.RW_WW)}</td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> MWh/a</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Commercial heat demand:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(featureAttributesWL.GHD_PW)}</td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> MWh/a</td>
                                </tr> */}
                                <tr>
                                    <td style={{ width: '200px' }}><b>Total heat demand:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(featureAttributesWL.RW_WW_GHD_PW)}</td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> MWh/a</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Heat line Density:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{spWBDisplay(featureAttributesWL.Waermedichte_RW_WW_GHD_PW)}</td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> kWh/ma</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ) : (
                    <p>No selection</p>
                )}
            </div>
        );
    } else {
        return (
            <div style={{ borderBottom: '1px solid gray', padding: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2>Single building</h2>
                    {feature_props && (
                        <button
                            className="button"
                            onClick={() => downloadJSON(featureAttributes, 'building_data.json')}
                        >
                            Download
                        </button>
                    )}
                </div>
                {feature_props ? (
                    <>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Function:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>{feature_props.nutzung || 'Keine Angabe'}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Building type:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>
                                        {feature_props.gebaeudetyp || 'Keine Angabe'}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Insulation level:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>
                                        {convertEEK(feature_props?.sanierungsstand)}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Heat area:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>
                                        {areaDisplay(feature_props.nutzflaeche)}
                                    </td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> m²</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Specific heat demand:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>
                                        {spWBDisplay(feature_props.spez_raum_waerme_wasser_waerme_bedarf)}
                                    </td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> kWh/m²a</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '200px' }}><b>Heat demand:</b></td>
                                    <td style={{ width: '140px', textAlign: 'right' }}>
                                        {absWBDisplay(feature_props.raum_waerme_wasser_waerme_bedarf)}{' '}
                                    </td>
                                    <td style={{ width: '140px', textAlign: 'left' }}> MWh/a</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                ) : (
                    <p>No selection</p>
                )}
            </div>
        );
    }
};

export default SingleBuildingInfo;
