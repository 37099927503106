/* global L */
import { React, useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, FeatureGroup, GeoJSON, useMapEvents, ZoomControl, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet-draw';
import leafletPip from '@mapbox/leaflet-pip';
import './MapComponent.css';
import { LegendComponent, legendHandler,  legendHandlerWL  } from './Legend.js';
import { MIN_ZOOM_LEVEL, STARTING_POSITION } from '../consts.js';

function centroidOfBB(bb){
    return [(bb._northEast.lat + bb._southWest.lat) / 2, (bb._northEast.lng + bb._southWest.lng) / 2]
}

function MapComponent({ currentGeoJSONData, currentGeoJSONDataWL,currentBoundingBox, setCurrentBoundingBox, selectedLegend, onFeatureClick, setAggregateData,setAggregateDataWL, setSelectedGeoJSONData, zoom, setZoom }) {

    const lastDrawnLayer = useRef(null);
    const [lastSelected, setLastSelected] = useState(null);

    const currentGeoJSONDataRef = useRef(null);
    const currentGeoJSONDataWLRef = useRef(null);

    useEffect(() => {
        currentGeoJSONDataRef.current = currentGeoJSONData;
        currentGeoJSONDataWLRef.current = currentGeoJSONDataWL;
    }, [currentGeoJSONData, currentGeoJSONDataWL]);

    function onEachFeature(feature, layer) {
        layer.on({
            click: function (e) {
                setLastSelected(feature.properties.fest_id);
                onFeatureClick(feature);
            }
        });
    }  
    
    function onEachFeatureWL(feature, layer) {
        layer.on({
            click: function (e) {
                setLastSelected(feature.properties.WLD_ID);
                onFeatureClick(feature);
            }
        });
    }

    function GeoJSONFeatures() {
        return (
            <div>
                <GeoJSON
                    data={currentGeoJSONData}
                    onEachFeature={onEachFeature}
                    style={(feature) => {
                        return legendHandler(selectedLegend, feature, zoom, lastSelected);
                    }
                    }
                />
                <GeoJSON
                    data={currentGeoJSONDataWL}
                    onEachFeature={onEachFeatureWL}
                    style={(feature) => {
                        return legendHandlerWL(feature, zoom, lastSelected);
                    }
                    }
                />

            </div>

        );
    }


    function MapState({ setZoom }) {
        const map = useMapEvents({
            zoomend() {
                setZoom(map.getZoom());
            },
            moveend() {
                const currentBounds = map.getBounds();
                setCurrentBoundingBox(currentBounds);
            },
            zoomlevelschange() {
                const currentBounds = map.getBounds();
                setCurrentBoundingBox(currentBounds);
            }
        });
        return null;
    }

    const handleDelete = (e) => {
    };

    function drawComplete(e) {
        if (lastDrawnLayer.current) {
            lastDrawnLayer.current.remove();
        }
        if (currentGeoJSONDataRef.current) {
            lastDrawnLayer.current = e.layer;

            const filteredData = filterFeatures(e.layer, currentGeoJSONDataRef.current, currentGeoJSONDataWLRef.current);
            setSelectedGeoJSONData(filteredData);
            handleSelectionDrawn(filteredData);
        } else {
            console.error("currentGeoJSONData is null");
        }


    };

    const handleSelectionDrawn = (filteredData) => {
        const updatedData = {
            objectCount: 0,
            totalArea: 0,
            totalHeatDemand: 0,
            buildingTypes: {},
            energyClasses: {}
        };

        updatedData.objectCount = filteredData.features.length;
        filteredData.features.forEach(feature => {
            updatedData.totalArea += feature.properties.nutzflaeche;
            updatedData.totalHeatDemand += feature.properties.raum_waerme_wasser_waerme_bedarf;
            updatedData.buildingTypes[feature.properties.gebaeudetyp] = (updatedData.buildingTypes[feature.properties.gebaeudetyp] || 0) + 1;
            updatedData.energyClasses[feature.properties.sanierungsstand] = (updatedData.energyClasses[feature.properties.sanierungsstand] || 0) + 1;
        });
        updatedData.buildingTypes = Object.fromEntries(Object.entries(updatedData.buildingTypes).sort());

        const updatedDataWL = {
            lines: 0,
            objectCount: 0,
            adressCount: 0,
            totalLength: 0,
            totalResDemand: 0,
            totalCommercialDemand: 0,
            totalDemand: 0,
            highestDensity: 0,
        };

        filteredData.featuresWL.forEach(feature => {
            updatedDataWL.lines += 1;
            updatedDataWL.objectCount += (feature.properties.Anzahl_Objekte && feature.properties.Anzahl_Objekte !== NaN && feature.properties.Anzahl_Objekte !== null) ? feature.properties.Anzahl_Objekte : 0;
            updatedDataWL.adressCount += (feature.properties.Anzahl_Adressen && feature.properties.Anzahl_Adressen !== NaN && feature.properties.Anzahl_Adressen !== null) ? feature.properties.Anzahl_Adressen : 0;
            updatedDataWL.totalLength += (feature.properties.Strassenlaenge && feature.properties.Strassenlaenge !== NaN && feature.properties.Strassenlaenge !== null) ? feature.properties.Strassenlaenge : 0;
            updatedDataWL.totalResDemand += (feature.properties.RW_WW &&  feature.properties.RW_WW !== NaN  && feature.properties.RW_WW !== null ) ? feature.properties.RW_WW : 0;
            updatedDataWL.totalCommercialDemand += (feature.properties.GHD_PW &&  feature.properties.GHD_PW !== NaN  && feature.properties.GHD_PW !== null ) ? feature.properties.GHD_PW : 0;
            updatedDataWL.totalDemand += (feature.properties.RW_WW_GHD_PW &&  feature.properties.RW_WW_GHD_PW !== NaN  && feature.properties.RW_WW_GHD_PW !== null ) ? feature.properties.RW_WW_GHD_PW : 0;
            updatedDataWL.highestDensity = Math.max(updatedDataWL.highestDensity, (feature.properties.Waermedichte_RW_WW_GHD_PW &&  feature.properties.Waermedichte_RW_WW_GHD_PW   !== NaN&& feature.properties.Waermedichte_RW_WW_GHD_PW !== null) ? feature.properties.Waermedichte_RW_WW_GHD_PW : 0);
        });

        setAggregateData(updatedData);
        setAggregateDataWL(updatedDataWL);
    };

    function filterFeatures(layer, data, dataWL) {
        const drawnShape = L.geoJSON(layer.toGeoJSON());
        const features = data.filter(feature => {
            const latLng = new L.LatLng(feature.geometry.coordinates[0][0][0][1], feature.geometry.coordinates[0][0][1][0]);
            return leafletPip.pointInLayer(latLng, drawnShape, true).length > 0;
        });

         const featuresWL = dataWL.filter(feature => {
             const latLng = new L.LatLng(feature.geometry.coordinates[0][0][1], feature.geometry.coordinates[0][1][0]);
             return leafletPip.pointInLayer(latLng, drawnShape, true).length > 0;
         });

        return {
            features: features,
            featuresWL: featuresWL
        };
    }

    return (

        <MapContainer
            center={currentBoundingBox ? centroidOfBB(currentBoundingBox) :STARTING_POSITION}
            zoom={zoom}
            zoomControl={false}
            style={{ height: "100%", width: "100%" }}
        >
            <TileLayer
                url={process.env.REACT_APP_TILEHOST_URL}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                maxZoom={19}
            />
            <FeatureGroup>
                <EditControl
                    position="topright"
                    onCreated={drawComplete}
                    onDeleted={handleDelete}
                    edit={{
                        edit: false,
                        remove: true,
                    }}
                    draw={{
                        rectangle: {
                            shapeOptions: { fill: false },
                        },
                        polygon: {
                            shapeOptions: { fill: false },
                        },
                        circle: false,
                        circlemarker: false,
                        marker: false,
                        polyline: false,
                        line: false,
                    }}
                />
                <ZoomControl position="topright" />
                <MapState setZoom={setZoom} />

            </FeatureGroup>
            <GeoJSONFeatures currentGeoJSONData={currentGeoJSONData} />

        </MapContainer>
    );
}

export default MapComponent;
