import React from 'react';

function areaDisplay(number) {
    return number?.toFixed(0).toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function absWBDisplay(number) {
    return (number / 1e3).toFixed(1).toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function spWBDisplay(number) {
    return number.toFixed(1).toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const from_years = [0, 1900, 1945, 1960, 1970, 1980, 1985, 1995, 2000, 2005, 2010, 2015];
const to_years = [1900, 1945, 1960, 1970, 1980, 1985, 1995, 2000, 2005, 2010, 2015, 2022];

const categories = from_years.map((from, index) => {
    const to = to_years[index];
    if (from === 0) {
        return '<1900';
    } else {
        return (to);
    }
});

function getCategory(year) {
    year = parseInt(year);
    for (let i = 0; i < from_years.length; i++) {
        if (year >= from_years[i] && year <= to_years[i]) {
            return categories[i];
        }
    }
    return categories[categories.length - 1];
}

function AggregateBuildingInfo({ aggregateData, aggregateDataWL, downloadJSON, selectedGeoJSONData }) {
    const parseBuildingTypes = () => {
        const buildingCategories = {};

        Object.entries(aggregateData.buildingTypes).forEach(([key, value]) => {
            const [type, year] = key.split('_');
            const category = getCategory(year);
            if (!buildingCategories[type]) {
                buildingCategories[type] = {};
            }
            if (!buildingCategories[type][category]) {
                buildingCategories[type][category] = 0;
            }
            buildingCategories[type][category] += value;
        });

        return buildingCategories;
    };

    const buildingTypes2D = parseBuildingTypes();

    const energyClassMapping = {
        1: 'A+',
        2: 'A',
        3: 'B',
        4: 'C',
        5: 'D',
        6: 'E',
        7: 'F',
        8: 'G',
        9: 'H',
    };
    const energyClasses = Object.fromEntries(Object.entries(aggregateData.energyClasses).map(([key, value]) => [energyClassMapping[key] || 'Unknown', value]));
    return (
        <div style={{ padding: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2>Building selection</h2>
                {(aggregateData.objectCount !== 0 || aggregateDataWL.lines !== 0) && (
                    <button className='button' onClick={() => {
                        downloadJSON(selectedGeoJSONData, 'aggregate_data.json');
                    }}>
                        Download
                    </button>
                )}
            </div>
            {aggregateData.objectCount !== 0 ? (
                <>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '140px' }}><b>Object count:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{aggregateData.objectCount}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Heat area:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{areaDisplay(aggregateData.totalArea)}</td>
                                <td style={{ width: '140px', textAlign: 'left' }}>m²</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Heat demand:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(aggregateData.totalHeatDemand)}</td>
                                <td style={{ width: '140px', textAlign: 'left' }}>MWh/a</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <tbody>
                            <tr style={{ padding: '10px' }}>
                                <td colSpan={categories.length + 1} style={{ textAlign: 'left' }}><b>Building type counts:</b></td>
                            </tr>
                            <tr>
                                <td><b>Type</b></td>
                                {categories.map(category => (
                                    <td key={category} style={{ width: '100px', textAlign: 'center' }}><b>{category}</b></td>
                                ))}
                            </tr>
                            {Object.keys(buildingTypes2D).map(type => (
                                <tr key={type}>
                                    <td><b>{type}</b></td>
                                    {categories.map(category => (
                                        <td key={category} style={{ width: '100px', textAlign: 'center' }}>
                                            {buildingTypes2D[type][category] || '-'}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br />
                    <table>
                        <tbody>
                            <tr style={{ padding: '10px' }}>
                                <td colSpan={2} style={{ textAlign: 'left' }}><b>Insulation level counts: </b></td>
                            </tr>
                            {Object.keys(energyClassMapping).map(key => {
                                const type = energyClassMapping[key];
                                const count = energyClasses[type] || "-";
                                return (
                                    <tr key={type}>
                                        <td style={{ width: '100px' }}><b>{type ? type + ":" : "Keine Angabe:"}</b></td>
                                        <td style={{ width: '100px', textAlign: 'right' }}>{count}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <br />
                    <h2>Heat line selection</h2>

                    <table>
                        <tbody>
                            <tr>
                                <td style={{ width: '200px' }}><b>Heat Lines:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{aggregateDataWL.lines}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Object Count:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{aggregateDataWL.objectCount}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Adress Count:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{aggregateDataWL.adressCount}</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Total length:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{areaDisplay(aggregateDataWL.totalLength)}</td>
                                <td style={{ width: '140px', textAlign: 'left' }}>m²</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Residential heat demand:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(aggregateDataWL.totalResDemand)}</td>
                                <td style={{ width: '140px', textAlign: 'left' }}>MWh/a</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Commercial heat demand:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(aggregateDataWL.totalCommercialDemand)}</td>
                                <td style={{ width: '140px', textAlign: 'left' }}>MWh/a</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Total heat demand:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(aggregateDataWL.totalDemand)}</td>
                                <td style={{ width: '140px', textAlign: 'left' }}>MWh/a</td>
                            </tr>
                            <tr>
                                <td style={{ width: '140px' }}><b>Highest present heat density:</b></td>
                                <td style={{ width: '140px', textAlign: 'right' }}>{absWBDisplay(aggregateDataWL.highestDensity)}</td>
                                <td style={{ width: '140px', textAlign: 'left' }}>MWh/ma</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            ) : <p>No selection</p>}
        </div>
    );
}

export default AggregateBuildingInfo;
